.headerMedios{
    background-color: #874AFA;
    color: white;
}

.headerTags{
    background-color: #a9aeb0;
    color: black;
}

ion-popover {
	--width: 50vw !important;
}

.popover-content.sc-ion-popover-md {
    -webkit-transform-origin: left top;
    transform-origin: left top;
    top: 25% !important;
    left: 25% !important;
}

.ion-color{
    --background-hover: var(--ion-color-tema);
    color: var(--ion-color-tema);
  }