.overlay {
    position: absolute;
    top:0px;
    left:0px;
    width: 100%;
    height: 100%;
    background: black;
    opacity: .7;
    z-index: 99999;
}

.overlayHide {
    position: absolute;
    top:0px;
    left:0px;
    width: 100%;
    height: 100%;
    background: black;
    opacity: .8;
    z-index: 99999;
    visibility: hidden;
}

.textoSpinner{
    background-color:black !important;
    color: #fcb23b !important;
    font-size: 40px !important;
    margin: 0 auto;
}