.sticky {
    position: sticky;
    top: 0;
    z-index: 2000;
    background-color: #b6cff8 !important; 
  }
.header {
  background-color: #b6cff8 !important;
}

.texto{
  font-size: 1.5vw !important;
}

.cartucho{
  background-color: bisque !important;
}

.fondo{
  background-color: #70a5d1 !important;
}

.height40{
  --min-height: 30px !important;
  height: 30px !important;
}
.infowindow .popover-content{
  width: 70vw !important;
}

.timetable{
  background-color: #b6cff8 !important;
}

.multiselect{
  width: 50%;
  border:cadetblue;
  border-radius: 25px;
}

.fondoCartucho{
  background-color: #5a0101 !important;
}

.rct-sidebar-row .highlight,
.react-calendar-timeline .rct-horizontal-lines .highlight {
  background-color: #5a0101 !important;
}
