button[title="Dejar de dibujar"] {
    background-image: url("./resources/mouse_right_click.svg") !important;
    background-position: center center !important;
    background-size: 30px 30px !important;
    background-repeat: no-repeat !important;
    height: 50px;
    width: 50px;
    cursor: pointer;
    border-radius: 5px !important;
    margin-right: 5px;
    background-color: var(--ion-color-menuamarillo) !important;
}

button[title="Dejar de dibujar"][aria-checked="true"] {
    height: 60px !important;
    width: 60px !important;
    background-color: var(--ion-color-tema) !important;
}

button[title="Dejar de dibujar"]:hover{
    background-color: var(--ion-color-menuamarillo) !important;
}

button[title="Dejar de dibujar"] span div {
    display: none;
}

button[title="Dibujar círculo"] {
    background-image: url("./resources/hand_with_pen.svg") !important;
    background-position: center center !important;
    background-size: 30px 30px !important;
    background-repeat: no-repeat !important;
    height: 50px;
    width: 50px;
    cursor: pointer;
    border-radius: 5px !important;
    background-color: var(--ion-color-menuamarillo) !important;
}

button[title="Dibujar círculo"][aria-checked="true"] {
    height: 60px !important;
    width: 60px !important;
    background-color: var(--ion-color-tema) !important;
}

button[title="Dibujar círculo"]:hover{
    background-color: var(--ion-color-tema) !important;
}

button[title="Dibujar círculo"] span div {
    display: none;
}

div[title="Dejar de dibujar"] {
    content: url("./resources/mouse_right_click.svg") !important;       
    height: 50px;
    width: 50px;
    cursor: pointer;
    border-radius: 5px !important;
    margin-right: 5px;
    background-color: var(--ion-color-tema-light) !important;
}

div[title="Dejar de dibujar"]:hover{
    background-color: var(--ion-color-tema) !important;
}

div[title="Trazar un círculo"] {
    content: url("./resources/hand_with_pen.svg") !important;
    height: 50px;
    width: 50px;
    cursor: pointer;
    border-radius: 5px !important;
    background-color: var(--ion-color-tema-light) !important;
}

div[title="Trazar un círculo"]:hover{
    background-color: var(--ion-color-tema) !important;
}

div[title="Dibujar círculo"] {
    content: url("./resources/hand_with_pen.svg") !important;
    height: 50px;
    width: 50px;
    cursor: pointer;
    border-radius: 5px !important;
    background-color: var(--ion-color-tema-light) !important;
}

div[title="Dibujar círculo"]:hover{
    background-color: var(--ion-color-tema) !important;
}
  
/* Tooltip container */
.tooltipPrincipal {
    position: relative;
}

.tooltipPrincipal .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 10px 0;
    border-radius: 6px;
    margin-top: 10px;
    right: 0;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    font-size: 12px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltipPrincipal:hover .tooltiptext {
    visibility: visible;
}

.tooltip {
    width: 50px;
}

.tooltip ion-fab-button {
    height: 40px;
    width: 40px;
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 10px 0;
    border-radius: 6px;
    margin-top: 10px;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    font-size: 12px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}

#contexMenu{
    left: 0px !important;
}


.menuPopUpCirculo {
    border: 1px solid var(--ion-color-tema) !important;
    padding: 1rem;
    background: var(--ion-color-tema) !important;
    color: #495057;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

/*Slider css */
.SlideWrapper {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 665;
}

.SlideWrapper--close {
    animation: undim 0.4s ease-out forwards;
}

.SlideWrapper--open {
    animation: dim 0.3s ease-out forwards;
}

.SlideModal {
    transition: width 0.3s ease-out;
}

.h-textRight {
    text-align: right;
}

.h-overflowHidden {
    overflow: hidden !important;
}

.h-overflowAuto {
    overflow: auto !important;
}

.h-displayFlex {
    display: flex !important;
}

.h-flexCol {
    flex-direction: column !important;
}

.h-flexSpaceBetween {
    justify-content: space-between !important;
}

.SlideModal {
    position: fixed;
    /*right: -50%;*/
    top: 0;
    bottom: 0;
    width: 28%;
    min-width: 450px;
    background-color: var(--ion-color-tema-light);
    z-index: 666;
}

.SlideModal--right {
    right: -50%;
}

.SlideModal--left {
    left: -50%;
}

.SlideModal--open.SlideModal--right {
    animation: slideIn--right 0.3s ease-out forwards;
}

.SlideModal--open.SlideModal--left {
    animation: slideIn--left 0.3s ease-out forwards;
}

@media (max-width: 520px) {
    .SlideModal--open {
        width: 100%;
    }
}

.SlideModal--close.SlideModal--right {
    animation: slideOut--right 0.3s ease-out forwards;
}

.SlideModal--close.SlideModal--left {
    animation: slideOut--left 0.3s ease-out forwards;
}

.SlideModal__header {
    margin-top: 100px;
    display: flex;
    align-items: center;
    background-color: var(--ion-color-tema);
    overflow: hidden;
    box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.2);
    z-index: 667;
}

.SlideModal__footer {
    box-shadow: 0 -3px 2px 0 rgba(0, 0, 0, 0.2);
}

.SlideModal__title {
    padding-left: 15px;
    color: #000;
}

@keyframes dim {
    from {
        background-color: transparent;
    }
    to {
        background-color: rgba(0, 0, 0, 0.5);
    }
}

@keyframes undim {
    from {
        background-color: rgba(0, 0, 0, 0.5);
    }
    to {
        background-color: transparent;
    }
}

@keyframes slideIn--right {
    0% {
        right: calc(-100%);
    }
    100% {
        right: 0;
    }
}

@keyframes slideOut--right {
    0% {
        right: 0;
    }
    100% {
        right: calc(-100%);
    }
}

@keyframes slideIn--left {
    0% {
        left: calc(-100%);
    }
    100% {
        left: 0;
    }
}

@keyframes slideOut--left {
    0% {
        left: 0;
    }
    100% {
        left: calc(-100%);
    }
}