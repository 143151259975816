.row{
    display: flex;
    flex-direction: row;
}

.space-between{
    justify-content: space-evenly;
    align-content: space-between;
}

.chips{
    width: 100%;
}

.half-chips{
    width: 50%;
}

.width{
    width: 100%;
}

.half-width{
    width: 50%;
}

.multi-select{
    /* width: 150%; */
    height: 38px;
    border-radius: 25px !important;
}

.search-by-clave{
    background-color:white;
    border: 1px solid #ced4da;
    padding: 0.25rem 0.5rem;
    border-radius: 25px;
    height: 38px;
}

.margin-search-by-clave{
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    margin-left: 15px;
}

.margin-bottom-ion-text{
    margin-bottom: 4px;
}