.inputlabel {
    color: black;
    font-size: 0.7rem;
  }

.xc {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%
}