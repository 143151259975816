.title-colum{
    display: flex;
    flex-direction: column;
    text-align: justify;
}

.values-title{
    display: flex;
    flex-direction: column;
    text-align: justify;
}

.container{
    display: flex;
    flex-direction: column;
    border: 0px solid #000;
}

.item {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
}

.title {
    color:black;
    flex: 0.3;
}

.value {
    flex: 1;
}

.header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
