.container-info-window-rutas{
    height: 100%;
    width: 100%;
}

.info-window-data-ruta{
    background-color: #ffd89a;
    display: flex;
}

.header-center{
    display: flex;
    justify-content: center;
    /* align-items: center; */
}

.info-window-card-header{
    /* display: flex; */
    justify-content: center;
    align-items: center;
}

.card-title{
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-info-window-ruta{
    text-align: center;
}

.column-info-window-ruta {
    margin-top: 2%;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

.center-images-div {
    padding: 1%;
}

.item-imagen-info-window-ruta{
    display: flex;
    justify-content: center;
    align-items: center;
}

.row-info-window-ruta {
    display: flex;
    flex-direction: row;
    align-items: center;
}