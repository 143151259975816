.center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container-lat-lng-abcmedios{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 5%;
    /* padding-bottom: 5%; */
}



.form-abc-medios-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 3%;
    /* margin-top: 5%; */
}

.form-abc-medios-col-item{
    flex-grow: 1; 
    /*width: 32%;*/
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.input-ubicacion-abc-medios{
    display: flex;
    justify-content: center;
}