.map-abcmedios-container{
    height: 40vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.button-form-abc-medios-map {
    /* height: 10vh; */
    /* width: 10vh; */
    background-color: #4854e0;
    border-radius: 12px;
    color: white;
    padding: 10px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    width: 10vh;
    margin-left: 45%;
    margin-bottom: 2%;
    margin-top: 2%;
    /* font-size: 16px; */
}

.button-form-abc-medios {
    /* height: 10vh; */
    /* width: 10vh; */
    background-color: #4854e0;
    border-radius: 12px;
    color: white;
    padding: 10px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    /* font-size: 16px; */
}

.button-form-abc-medios-disable {
    /* height: 10vh; */
    /* width: 10vh; */
    background-color: gray;
    border-radius: 12px;
    color: white;
    padding: 10px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    /* font-size: 16px; */
}

.button-form-abc-medios-disable-map {
    background-color: gray;
    border-radius: 12px;
    color: white;
    padding: 10px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    width: 10vh;
    margin-left: 45%;
    margin-bottom: 2%;
    margin-top: 2%;
    /* font-size: 16px; */
}

.button-form-abc-medios:hover {background-color: #4854e0}

.button-form-abc-medios:active {
  background-color: #4854e0;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}