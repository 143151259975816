.drop-image{
    background-color: aqua;
    flex: 1;
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
}

.row{
    display: flex;
    flex-direction: row;
}

.center{
    justify-content: center;
    align-items: center;
}
