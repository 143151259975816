
.login {
   /* --background: url('/assets/fondo.png') no-repeat center center / cover;*/
  }
  
  .login-centrado{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
  }
  
  .login-items{
    --background: rgba(32, 32, 32, 0.792);
    color: white;
  }
  
  .login-items ion-label {
    margin-right: 15px;
    text-align: right;
  }
  
  .transparente {
    background: transparent;
  }