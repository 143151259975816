:root{
    --rmsc-radius: 25px;
}

.toolbar{
    position: absolute;
    top: 0;
    left: 0;

}

.checkboxPlaces{
    padding-right: 10px;
}

.alert-wrapper.sc-ion-alert-md{
    max-width: 250px !important;
    width: 250px !important;
}

.react-dropdown-select-dropdown {
    min-width: 150px !important;
}

.p-panel-titlebar-icon {
    background-color: rgb(95, 95, 95) !important;
    border-radius: 50% !important;
    height: 30px !important;
    width: 30px !important;
    margin-top: -5px !important;
    color: white !important;
}

.p-panel-titlebar-icon span {
    margin-top: 5px !important;
}

.p-panel-toggler {
    background-color: #fcb23b !important;
    color: black !important;
}