/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

@font-face {
  font-family: "Dia Regular"; 
  src: url("//db.onlinewebfonts.com/t/2f798f78461b6aaa968ffd08fe9280bf.eot"); 
  src: url("//db.onlinewebfonts.com/t/2f798f78461b6aaa968ffd08fe9280bf.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/2f798f78461b6aaa968ffd08fe9280bf.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/2f798f78461b6aaa968ffd08fe9280bf.woff") format("woff"), url("//db.onlinewebfonts.com/t/2f798f78461b6aaa968ffd08fe9280bf.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/2f798f78461b6aaa968ffd08fe9280bf.svg#Dia Regular") format("svg"); 
  font-style: normal;
  font-weight: 800;
}

@media (max-width: 1024px) {
  table{
    font-size: 1.2vw;
  }
}

body {
  font-family: 'Dia Regular';
}

/** Ionic CSS Variables **/
:root {
  
  --ion-font-family: 'Dia Regular';
  /** primary **/
  --ion-color-primary: #fcb32b;
  --ion-color-primary-rgb: 255, 196, 9;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #e0ac08;
  --ion-color-primary-tint: #ffca22;

  /** secondary **/
  --ion-color-secondary: #126efa;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #d11787;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #1cbf89;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #fcb32b;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #fc3d38;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-menu: #d11787;
  --ion-color-menu-rgb: 209,23,135;
  --ion-color-menu-contrast: #ffffff;
  --ion-color-menu-contrast-rgb: 255,255,255;
  --ion-color-menu-shade: #b81477;
  --ion-color-menu-tint: #d62e93;

  --ion-color-menurojo: #fc3d38;
  --ion-color-menurojo-rgb: 252,61,56;
  --ion-color-menurojo-contrast: #ffffff;
  --ion-color-menurojo-contrast-rgb: 255,255,255;
  --ion-color-menurojo-shade: #de3631;
  --ion-color-menurojo-tint: #fc504c;

  --ion-color-menuturquesa: #1cb5b2;
  --ion-color-menuturquesa-rgb: 28,181,178;
  --ion-color-menuturquesa-contrast: #000000;
  --ion-color-menuturquesa-contrast-rgb: 0,0,0;
  --ion-color-menuturquesa-shade: #199f9d;
  --ion-color-menuturquesa-tint: #33bcba;

  --ion-color-menuesmeralda: #1cbf59;
  --ion-color-menuesmeralda-rgb: 28,191,89;
  --ion-color-menuesmeralda-contrast: #000000;
  --ion-color-menuesmeralda-contrast-rgb: 0,0,0;
  --ion-color-menuesmeralda-shade: #19a84e;
  --ion-color-menuesmeralda-tint: #3ae07a;

  --ion-color-menulima: #bfeb08;
  --ion-color-menulima-rgb: 191,235,8;
  --ion-color-menulima-contrast: #000000;
  --ion-color-menulima-contrast-rgb: 0,0,0;
  --ion-color-menulima-shade: #a8cf07;
  --ion-color-menulima-tint: #c5ed21;

  --ion-color-menuamarillo: #fcb23b;
  --ion-color-menuamarillo-rgb: 252,178,59;
  --ion-color-menuamarillo-contrast: #000000;
  --ion-color-menuamarillo-contrast-rgb: 0,0,0;
  --ion-color-menuamarillo-shade: #de9d34;
  --ion-color-menuamarillo-tint: #fcba4f;

  --ion-color-menuazul: #126efa;
  --ion-color-menuazul-rgb: 18,110,250;
  --ion-color-menuazul-contrast: #ffffff;
  --ion-color-menuazul-contrast-rgb: 255,255,255;
  --ion-color-menuazul-shade: #1061dc;
  --ion-color-menuazul-tint: #2a7dfb;

  /* Cambio de colores al tema principal */

  --ion-color-tema: #bad3fc;
  --ion-color-tema-rgb: 219,201,253;
  --ion-color-tema-contrast: #000000;
  --ion-color-tema-contrast-rgb: 0,0,0;
  --ion-color-tema-shade: #c6a8ff;
  --ion-color-tema-tint: #e7dbfe;
  --ion-color-tema-light: #d1e2fe;

  --ion-text-color: #000000;
  --ion-text-color-rgb: 0,0,0;

  --ion-color-step-50: #efaa29;
  --ion-color-step-100: #e3a127;
  --ion-color-step-150: #d69825;
  --ion-color-step-200: #ca8f22;
  --ion-color-step-250: #bd8620;
  --ion-color-step-300: #b07d1e;
  --ion-color-step-350: #a4741c;
  --ion-color-step-400: #976b1a;
  --ion-color-step-450: #8b6218;
  --ion-color-step-500: #7e5a16;
  --ion-color-step-550: #715113;
  --ion-color-step-600: #654811;
  --ion-color-step-650: #583f0f;
  --ion-color-step-700: #4c360d;
  --ion-color-step-750: #3f2d0b;
  --ion-color-step-800: #322409;
  --ion-color-step-850: #261b06;
  --ion-color-step-900: #191204;
  --ion-color-step-950: #0d0902;

  /*Prueba de color*/
  --ion-color-resaltar: #ffd89a;
  --ion-color-resaltar-rgb: 255, 255, 153;
  --ion-color-resaltar-contrast: #000000;
  --ion-color-resaltar-contrast-rgb: 0,0,0;

}

/* Tamaño de fuente para los data table */
.p-component{
  font-size: 0.8vw !important;
}

.p-link{
  font-size: 0.8vw !important;
}

.foursqare{
  border-radius: 25px;
}

.foursqare .p-inputtext.p-chips-multiple-container{
  border-radius: 25px;
}

.multi-select {
  --rmsc-main: #4285f4;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ccc;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 10px; /* Spacing */
  --rmsc-radius: 25px !important; /* Radius */
  --rmsc-h: 38px; /* Height */
  max-width: 200px;
  min-width: 200px;
  font-size: 12px;
  opacity: .9 !important;
  margin-top: 5px !important;
  margin-left: 10px;
}

.ion-color-menu {
  --ion-color-base: var(--ion-color-menu);
  --ion-color-base-rgb: var(--ion-color-menu-rgb);
  --ion-color-contrast: var(--ion-color-menu-contrast);
  --ion-color-contrast-rgb: var(--ion-color-menu-contrast-rgb);
  --ion-color-shade: var(--ion-color-menu-shade);
  --ion-color-tint: var(--ion-color-menu-tint);
}

.ion-color-menurojo {
  --ion-color-base: var(--ion-color-menurojo);
  --ion-color-base-rgb: var(--ion-color-menurojo-rgb);
  --ion-color-contrast: var(--ion-color-menurojo-contrast);
  --ion-color-contrast-rgb: var(--ion-color-menurojo-contrast-rgb);
  --ion-color-shade: var(--ion-color-menurojo-shade);
  --ion-color-tint: var(--ion-color-menurojo-tint);
}

.ion-color-menuturquesa {
  --ion-color-base: var(--ion-color-menuturquesa);
  --ion-color-base-rgb: var(--ion-color-menuturquesa-rgb);
  --ion-color-contrast: var(--ion-color-menuturquesa-contrast);
  --ion-color-contrast-rgb: var(--ion-color-menuturquesa-contrast-rgb);
  --ion-color-shade: var(--ion-color-menuturquesa-shade);
  --ion-color-tint: var(--ion-color-menuturquesa-tint);
}

.ion-color-menuesmeralda {
  --ion-color-base: var(--ion-color-menuesmeralda);
  --ion-color-base-rgb: var(--ion-color-menuesmeralda-rgb);
  --ion-color-contrast: var(--ion-color-menuesmeralda-contrast);
  --ion-color-contrast-rgb: var(--ion-color-menuesmeralda-contrast-rgb);
  --ion-color-shade: var(--ion-color-menuesmeralda-shade);
  --ion-color-tint: var(--ion-color-menuesmeralda-tint);
}

.ion-color-menulima {
  --ion-color-base: var(--ion-color-menulima);
  --ion-color-base-rgb: var(--ion-color-menulima-rgb);
  --ion-color-contrast: var(--ion-color-menulima-contrast);
  --ion-color-contrast-rgb: var(--ion-color-menulima-contrast-rgb);
  --ion-color-shade: var(--ion-color-menulima-shade);
  --ion-color-tint: var(--ion-color-menulima-tint);
}

.ion-color-menuamarillo {
  --ion-color-base: var(--ion-color-menuamarillo);
  --ion-color-base-rgb: var(--ion-color-menuamarillo-rgb);
  --ion-color-contrast: var(--ion-color-menuamarillo-contrast);
  --ion-color-contrast-rgb: var(--ion-color-menuamarillo-contrast-rgb);
  --ion-color-shade: var(--ion-color-menuamarillo-shade);
  --ion-color-tint: var(--ion-color-menuamarillo-tint);
}

.ion-color-menuazul {
  --ion-color-base: var(--ion-color-menuazul);
  --ion-color-base-rgb: var(--ion-color-menuazul-rgb);
  --ion-color-contrast: var(--ion-color-menuazul-contrast);
  --ion-color-contrast-rgb: var(--ion-color-menuazul-contrast-rgb);
  --ion-color-shade: var(--ion-color-menuazul-shade);
  --ion-color-tint: var(--ion-color-menuazul-tint);
}

.ion-color-tema {
  --ion-color-base: var(--ion-color-tema);
  --ion-color-base-rgb: var(--ion-color-tema-rgb);
  --ion-color-contrast: var(--ion-color-tema-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tema-contrast-rgb);
  --ion-color-shade: var(--ion-color-tema-shade);
  --ion-color-tint: var(--ion-color-tema-tint);
} 

.ion-color-resaltar {
  --ion-color-base: var(--ion-color-resaltar);
  --ion-color-base-rgb: var(--ion-color-resaltar-rgb);
  --ion-color-contrast: var(--ion-color-resaltar-contrast);
  --ion-color-contrast-rgb: var(--ion-color-resaltar-contrast-rgb);
} 

.sc-ion-input-md-h {
  --padding-top: 7px;
  --padding-end: 0;
  --padding-bottom: 7px;
  --padding-start: 8px;
  font-size: inherit;
}


/** Elementos del menú **/
.split-pane-visible >.split-pane-side {
  min-width: 270px!important;
  max-width: 270px!important;
}
/*
ion-menu {
  --ion-background-color: var(--ion-color-menuamarillo);
  --ion-text-color: var(--ion-color-primary-contrast);
}

ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}*/

ion-note {
  display: inline-block;
  font-size: 16px;
  color: var(--ion-color-medium-shade);
}

/** fin menú **/
.p-button-success {
  background: #1cbf89 !important;
  border: 1px solid #1cbf89 !important;
}

.p-button-warning {
  background: #fcb32b !important;
  border: 1px solid #fcb32b !important;
}

.p-button-danger {
  background: #fc3d38 !important;
  border: 1px solid #fc3d38 !important;
}

.p-button-help {
  background: #d11787 !important;
  border: 1px solid #d11787 !important;  
}
/*
.p-panel-content{
  overflow: visible;
  background-color:#FBE4B8 !important;
}

.p-panel-header {
  border: 1px solid #FAC778 !important;
  background-color: #FAC778 !important;
  color: black !important;
}*/

.p-panel-content{
  overflow: visible;
  background-color:var(--ion-color-tema-light) !important;
}

.p-panel-header {
  border: 1px solid var(--ion-color-tema-light) !important;
  background-color: var(--ion-color-tema-light) !important;
  color: black !important;
}

/***************CSS AGREGADO POR FER************************/
.fc-more-popover {
  max-height: 95% !important;
  overflow-y: auto !important;
}
/*************** FIN DE CSS AGREGADO POR FER************************/
