.center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.module-wrapper {
    position: relative;
  }
  .module {
    width: 200px;
    margin: 20px auto;
  }
  .module-button {
    background: rgba(255,255,255,.15);
    /* color: white; */
    border: 2px solid rgba(255,255,255,.75);
    width: 100%;
    padding: 30px;
    text-transform: uppercase;
    border-radius: 4px;
    font-size: 1.2em;
    transition: 300ms;
    
  }

  .module-button-otro {
    display:inline-block;
    color:#FFF;
    border:1px solid #CCC;
    background:#0d89ec;
    box-shadow: 0 0 5px -1px rgba(0,0,0,0.2);
    cursor:pointer;
    vertical-align:middle;
    /* max-width: 100px; */
    padding: 10px;
    text-align: center;
  }