.dropdown-heading{
    border: 5px solid #fcb23b !important;
    border-radius: 25px !important;
    opacity: .9 !important;
}

.dropdown-container{
    border-radius: 25px !important;
}

.listaRutas{
    background-color: rgba(255, 196, 140, 0.8);
    word-wrap: break-word;
    overflow-x: hidden;
    overflow-y: scroll;
    min-width: 200px;
    max-width: 200px;
    text-align: justify;
    border-radius: 25px;
    margin-left: 10px;
    font-size: 12px;
}