.container-notes{
    display: flex;
    flex: 1;
    flex-direction: column;
}

.column{
    margin-top: 2%;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

.item-note {
    border: 1px solid #000;
    padding-left: 2%;
    padding-right: 2%;
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 1%;
}

.title-note{
    padding-top: 1%;
    padding-bottom: 1%;
    margin-left: 3%;
}

.titleContainer{
    justify-content: start;
    align-content: flex-start;
}