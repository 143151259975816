@page { 
    size: Letter landscape;
    color-adjust:exact;
}
.container {
    flex: 1;
    align-items: center;
    justify-content: center;
    justify-items: center;
    height: '10%'; 
    width: '10%';
}

.logo{
    position: absolute;
    left: 33px;
    top: 24px;
    width: 150px;
    height: 60px;
}

.principal{
    min-width:11in !important;
    max-width:11in !important;
    min-height: 8.5in !important;
    max-height: 8.5in !important;
    display: block;
    position: relative;
    margin-top: auto;
    margin: auto;
}

.principalMultiple{
    min-width:11in !important;
    max-width:11in !important;
    min-height: 8.5in !important;
    max-height: 8.5in !important;
    display: block;
    position: relative;
    margin-top: auto;
    margin: auto;
    break-after: auto;
    break-before: always;
    break-inside: auto;
    page-break-after: always;
}

[ion-fixed] {
    min-width:980px !important;
    max-width:980px !important;
    min-height: 670px !important;
    max-height: 670px !important;
}

h6{
    margin-top: 5px;
    margin-bottom: 3px;
}

.fontSizeCuadroSinBorde{
    font-size: 9px,
}

.footer{
    position: absolute;
    display: table;
    left: 0;
    bottom: 0;
    height: 50px;
    width: 100%;
    color: white;
    padding: 10px;
    background-color: black;
    font-size: 12px !important;
}
.footerMultiple{
    position: absolute;
    display: table;
    left: 0;
    bottom: 0;
    height: 50px;
    width: 100%;
    color: white;
    padding: 10px;
    background-color: black;
    font-size: 12px !important;
    margin-bottom: 1000px;
}

.floatright{
    float: right;
}

.fotoSitio{
    position: absolute;
    min-width: 507px !important;
    left: 254;
    top: 0;
}