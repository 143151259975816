.container{
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;
    /* height: 100%; */
    /* background-color: aqua; */
    flex-direction: column;
    border: 1px solid #000;
    border-width: 1;
    border-color: black;
    margin-bottom: 5%;
}

.other-item{
    display: flex;
    flex-grow: 1; /* default 0 */
}

.item-main-nombre{
    flex-direction: row;
    width: 50%;
    align-items: center;
    justify-content: center;
    /* flex-grow: 10; default 0 */
}

.item-main-derroteo{
    flex-direction: row;
    width: 80%;
    align-items: center;
    justify-content: center;
    /* flex-grow: 10; default 0 */
}

.row{
    flex-direction: row;
}

.flex{
    display: flex;
    flex: 1;
    width: 35vw;
    align-items: center;
    justify-content: center;
}

p{
    text-align: justify;
}

.center{
    align-items: center;
    justify-content: center;
}

